export const weddingPhotos = [
  ["weddings/IMG_0916.jpg", "weddings/IMG_1099.jpg"],
  [
    "weddings/IMG_0870.jpg",
    "weddings/IMG_0980.jpg",
    "weddings/IMG_1026.jpg",
    "weddings/IMG_0864.jpg",
  ],
  [
    "weddings/IMG_2442-2.jpg",
    "weddings/IMG_0836.jpg",
    "weddings/IMG_0934.jpg",
    "weddings/IMG_2470.jpg",
  ],
  [
    "weddings/IMG_2143.jpg",
    "weddings/IMG_0737.jpg",
    "weddings/IMG_0862.jpg",
    "weddings/IMG_2146.jpg",
  ],
  ["weddings/IMG_5311.jpg", "weddings/IMG_5330.jpg"],
  ["weddings/IMG_5655.jpg", "weddings/IMG_5347.jpg"],
  [
    "weddings/IMG_5360.jpg",
    "weddings/IMG_5615.jpg",
    "weddings/IMG_5885.jpg",
    "weddings/IMG_5415.jpg",
  ],
  ["weddings/IMG_6055.jpg", "weddings/IMG_6709.jpg"],
  [
    "weddings/IMG_6372.jpg",
    "weddings/IMG_6704.jpg",
    "weddings/IMG_6724.jpg",
    "weddings/IMG_6542.jpg",
  ],
  [
    "weddings/IMG_8104-2.jpg",
    "weddings/IMG_8002.jpg",
    "weddings/IMG_8017.jpg",
    "weddings/IMG_7979.jpg",
  ],
  [
    "weddings/IMG_9019.jpg",
    "weddings/IMG_9065.jpg",
    "weddings/IMG_9078.jpg",
    "weddings/IMG_9052.jpg",
  ],
  ["weddings/IMG_9026.jpg", "weddings/IMG_9218.jpg"],
  ["weddings/IMG_8944.jpg", "weddings/IMG_8805.jpg"],
  [
    "weddings/IMG_8849.jpg",
    "weddings/IMG_8895.jpg",
    "weddings/IMG_8931.jpg",
    "weddings/IMG_8940.jpg",
  ],
  [
    "weddings/IMG_4375.jpg",
    "weddings/IMG_4352.jpg",
    "weddings/IMG_4551.jpg",
    "weddings/IMG_4573.jpg",
  ],
  [
    "weddings/IMG_4318.jpg",
    "weddings/IMG_4373.jpg",
    "weddings/IMG_4570.jpg",
    "weddings/IMG_4331.jpg",
  ],
  ["weddings/IMG_5354.jpg", "weddings/IMG_5381.jpg"],
  [
    "weddings/IMG_4621.jpg",
    "weddings/IMG_4605.jpg",
    "weddings/IMG_4612.jpg",
    "weddings/IMG_4632.jpg",
  ],
  ["weddings/IMG_0456.jpg", "weddings/IMG_0472.jpg"],
  [
    "weddings/IMG_0333.jpg",
    "weddings/IMG_0336.jpg",
    "weddings/IMG_0428.jpg",
    "weddings/IMG_0442.jpg",
  ],
]
